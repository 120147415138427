import Services from "../components/Services";
import BannersSlider from "../shared/components/BannersSlider";
import GetInTouch from "../shared/components/GetInTouch";

const Home = () => {
  return (
    <div>
      <BannersSlider />
      <Services />
      <GetInTouch />
    </div>
  );
};

export default Home;
