import logo_en from "../../assets/images/main-logo.png";
import logo_ar from "../../assets/images/main-logo.png";

import lang_en from "../../assets/images/en.png";
import lang_ar from "../../assets/images/ar.png";

import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const SideMenu = () => {
  const toogleMenuRef = useRef<HTMLSpanElement>(null);
  const { t, i18n } = useTranslation("common");
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
    closeMenu();
  };
  const handleToggleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const $this = $(event.currentTarget);

    if ($("body").hasClass("offcanvas")) {
      $this.removeClass("active");
      $("body").removeClass("offcanvas");
    } else {
      $this.addClass("active");
      $("body").addClass("offcanvas");
    }
  };

  const closeMenu = () => {
    if (window.innerWidth < 768) {
      toogleMenuRef?.current?.click();
    }
  };
  const location = useLocation();
  return (
    <div>
      <span
        className="js-fh5co-nav-toggle fh5co-nav-toggle"
        onClick={handleToggleClick}
        ref={toogleMenuRef}
      >
        <i></i>
      </span>
      <aside id="fh5co-aside" className="border js-fullheight">
        <h1 id="fh5co-logo">
          <NavLink to="/" onClick={closeMenu}>
            <img src={i18n.language === "en" ? logo_en : logo_ar} alt="logo" />
          </NavLink>
        </h1>
        <nav id="fh5co-main-menu" role="navigation">
          <ul>
            <li className={location.pathname === "/" ? "fh5co-active" : ""}>
              <NavLink to="/" onClick={closeMenu}>
                {t("sideMenu.home")}
              </NavLink>
            </li>
            <li
              className={
                location.pathname === "/services" ? "fh5co-active" : ""
              }
            >
              <NavLink to="/services" onClick={closeMenu}>
                {" "}
                {t("sideMenu.services")}
              </NavLink>
            </li>
            <li
              className={
                location.pathname === "/projects" ? "fh5co-active" : ""
              }
            >
              <NavLink to="/projects" onClick={closeMenu}>
                {" "}
                {t("sideMenu.projects")}
              </NavLink>
            </li>
            <li
              className={
                location.pathname === "/about-us" ? "fh5co-active" : ""
              }
            >
              <NavLink to="/about-us" onClick={closeMenu}>
                {" "}
                {t("sideMenu.ourVesion")}
              </NavLink>
            </li>
            <li
              className={
                location.pathname === "/contact-us" ? "fh5co-active" : ""
              }
            >
              <NavLink to="/contact-us" onClick={closeMenu}>
                {t("sideMenu.contactUs")}
              </NavLink>
            </li>
            <li>
              <div
                className="language-btn"
                id="languageBtn"
                onClick={() =>
                  changeLanguage(i18n.language === "en" ? "ar" : "en")
                }
              >
                <img
                  src={i18n.language === "en" ? lang_ar : lang_en}
                  alt="Language Flag"
                />
                {/* <span className="language-text">
                  {i18n.language === "en" ? t("ar") : t("en")}
                </span> */}
              </div>
            </li>
          </ul>
        </nav>

        <div className="fh5co-footer">
          <p>
            <small>
               &copy;{t('footer.text')}
              {/* <span>
                Designed For Mozaik
              </span> */}
            </small>
          </p>
          <ul>
            <li>
              <a href="https://www.facebook.com/profile.php?id=61554608949643">
                <i className="icon-facebook2"></i>
              </a>
            </li>
            {/* <li>
                <a href="/"><i className="icon-twitter2"></i></a>
                </li>  */}
            <li>
              <a href="https://www.instagram.com/mozaikeng?igsh=MWtqYnRicGJ2dHFqdw%3D%3D&utm_source=qr">
                <i className="icon-instagram"></i>
              </a>
            </li>
            <a href="https://maps.app.goo.gl/niFDGTvxjwf7n7By6?g_st=iw" className="map-i">
              <i className="icon-location"></i>
            </a>
            <a href="https://wa.me/971544880772" className="map-i">
              <i className="icon-whatsapp"></i>
            </a>
            {/* <li>
                <a href="/"><i className="icon-linkedin2"></i></a>
                </li> */}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default SideMenu;
