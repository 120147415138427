import { NavLink } from "react-router-dom";

import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

const GetInTouch = () => {
  const { t, i18n } = useTranslation("common");
  const fadeInStart = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(${i18n.language === "en" ? "-50px" : "50px"})`,
    },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 1000 }, // Adjust the duration as needed
  });
  return (
    <div id="get-in-touch">
      <div className="fh5co-narrow-content">
        <div className="row">
          <animated.div
            className="col-md-4 animate-box"
            data-animate-effect="fadeInStart"
            style={fadeInStart}
          >
            <h1 className="fh5co-heading-colored">{t("getInTouch")}</h1>
          </animated.div>
        </div>
        <div className="row">
          <animated.div
            className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
            data-animate-effect="fadeInStart"
            style={fadeInStart}
          >
            <p className="fh5co-lead">
              {t("getInTouchTitle")}
            </p>
            <p>
              <NavLink to="/contact-us">
                <button className="btn btn-primary">{t("contactUs")}</button>
              </NavLink>
            </p>
          </animated.div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
