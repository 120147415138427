import ServiceCards from "../components/ServiceCards";
import GetInTouch from "../shared/components/GetInTouch";

const Services = () => {
  return (
    <div>
      <ServiceCards />
      <GetInTouch />
    </div>
  );
};

export default Services;
