import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useState } from "react";

const ContactUs = () => {
  let initValues = {
    name: "",
    email: "",
    phone: "",
    message: "",
  }
  const [formData, setFormData] = useState(initValues);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const sendMessage = async (e: any) => {
    e.preventDefault();
    let body = {
      "data": {
        "Name": formData.name,
        "Email": formData.email,
        "Phone":formData.phone,
        "Message": formData.message,
        "Timestamp": new Date().toLocaleString()
      }
    }
    try {
      let url =
        "https://api.apispreadsheets.com/data/5gZW302AxsX9vmKJ/";

      const response = await axios.post(
        url,
        JSON.stringify(body),
      );

      if (response.status === 201) {
        setFormData(initValues)
        // Optionally, update your UI or perform other actions after successful submission
      } else {
        console.error("Error submitting data:", response.statusText);
      }
    } catch (error: any) {
      console.error("Error submitting data:", error.message);
    }
  };
  const { i18n, t } = useTranslation("common");
  const contacts = {
    email: "Info@mozaikengcon.com",
    phone: "+971 58 585 2210",
    location: t("location"),
  };
  const fadeInStart = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(${i18n.language === "en" ? "-50px" : "50px"})`,
    },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 1000 }, // Adjust the duration as needed
  });
  return (
    <div>
      <div className="fh5co-more-contact">
        <div className="fh5co-narrow-content">
          <div className="row">
            <div className="col-md-4">
              <animated.div
                className="fh5co-feature fh5co-feature-sm animate-box"
                data-animate-effect="fadeInStart"
                style={fadeInStart}
              >
                <div className="fh5co-icon">
                  <i className="icon-globe fs-25"></i>
                </div>
                <div className="fh5co-text fs-l">
                  <p>
                    <a href="/">{contacts.email}</a>
                  </p>
                </div>
              </animated.div>
            </div>
            <div className="col-md-4">
              <animated.div
                className="fh5co-feature fh5co-feature-sm animate-box"
                data-animate-effect="fadeInStart"
                style={fadeInStart}
              >
                <div className="fh5co-icon">
                  <i className="icon-map fs-25"></i>
                </div>
                <div className="fh5co-text fs-l">
                  <p>{contacts.location}</p>
                </div>
              </animated.div>
            </div>
            <div className="col-md-4">
              <animated.div
                className="fh5co-feature fh5co-feature-sm animate-box"
                data-animate-effect="fadeInStart"
                style={fadeInStart}
              >
                <div className="fh5co-icon">
                  <i className="icon-phone fs-25"></i>
                </div>
                <div className="fh5co-text fs-l">
                  <p>
                    <a href="tel://">{contacts.phone}</a>
                  </p>
                </div>
              </animated.div>
            </div>
          </div>
        </div>
      </div>
      <animated.div
        className="fh5co-narrow-content animate-box"
        data-animate-effect="fadeInStart"
        style={fadeInStart}
      >
        <div className="row">
          <div className="col-md-4">
            <h2>{t("getInTouch")}</h2>
          </div>
        </div>
        <form action="">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("placeholders.name") || "name"}
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("placeholders.email") || "email"}
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("placeholders.phone") || "phone"}
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <textarea
                      id="message"
                      cols={30}
                      rows={7}
                      className="form-control"
                      placeholder={t("placeholders.message") || "message"}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-primary btn-md"
                      value={t("placeholders.sendMessage") || "Send Message"}
                      onClick={sendMessage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </animated.div>
    </div>
  );
};

export default ContactUs;
