import ServiceCard from "../shared/components/ServiceCard";
import Title from "../shared/components/Title";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

const ServiceCards = () => {
  const { t, i18n } = useTranslation("common");

  const services = [
    {
      id: "1",
      title: t("serviceCards.VILLAS"),
      paragraph: t("serviceCards.text"),
      image: "/images/villa1.jpeg",
    },
    {
      id: "2",
      title: t("serviceCards.COMMERCE_BUILDINGS"),
      paragraph: t("serviceCards.text"),
      image: "/images/e-commerce1.jpeg",
    },
    {
      id: "3",
      title: t("serviceCards.RESIDENTIAL_BUILDINGS"),
      paragraph: t("serviceCards.text"),
      image: "/images/building1.jpeg",
    },
    {
      id: "4",
      title: t("serviceCards.MOSQUES"),
      paragraph: t("serviceCards.text"),
      image: "/images/msq1.jpeg",
    },
    {
      id: "5",
      title: t("serviceCards.WAREHOUSES"),
      paragraph: t("serviceCards.text"),
      image: "/images/bussiness7.jpeg",
    },
    {
      id: "6",
      title: t("serviceCards.FACTORIES"),
      paragraph: t("serviceCards.text"),
      image: "/images/bussiness4.jpeg",
    },
  ];
  const fadeInStart = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(${i18n.language === "en" ? "-50px" : "50px"})`,
    },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 1000 }, // Adjust the duration as needed
  });
  return (
    <div className="fh5co-narrow-content">
      <Title title={"ourServices"} />
      <div className="row row-bottom-padded-md">
        {services.map((service) => (
          <animated.div
            className="col-md-3 col-sm-6 col-padding animate-box"
            data-animate-effect="fadeInStart"
            style={fadeInStart}
            key={service.id}
          >
            <ServiceCard {...service} />
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCards;
