import { useTranslation } from "react-i18next";
import Services from "../components/Services";
import BannerWTitle from "../shared/components/BannerWTitle";
import GetInTouch from "../shared/components/GetInTouch";

const AboutUs = () => {
  const { t } = useTranslation("common");

  const bannerData = {
    name: t("aboutUs.aboutTitle"),
    paragraph1: t("aboutUs.paragraph"),
    paragraph2: t("aboutUs.author"),
    image: "images/banner1.jpeg",
  };
  return (
    <div>
      <BannerWTitle {...bannerData} />
      <Services />
      <GetInTouch />
    </div>
  );
};

export default AboutUs;
