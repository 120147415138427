import Project from "../shared/components/Project";
import Title from "../shared/components/Title";
import { PhotoProvider, PhotoView, PhotoSlider } from "react-photo-view";

const ProjectsCards = () => {
  const sections = [
    { id: "project1", name: "projects.project 1" },
    { id: "project2", name: "projects.project 2" },
    { id: "project3", name: "projects.project 3" },
    { id: "project4", name: "projects.project 4" },
    { id: "project5", name: "projects.project 5" },
    { id: "project6", name: "projects.project 6" },
    { id: "project7", name: "projects.project 7" },
    { id: "project8", name: "projects.project 8" },
    { id: "project9", name: "projects.project 9" },
  ];
  const projects = [
    {
      id: "1",
      name: "Project Name",
      type: "Brading",
      image: "/images/project1.jpg",
    },
    {
      id: "2",
      name: "Project Name",
      type: "Brading",
      image: "/images/project1a.jpg",
    },
    {
      id: "3",
      name: "Project Name",
      type: "Brading",
      image: "/images/project1b.jpg",
    },
    {
      id: "4",
      name: "Project Name",
      type: "Brading",
      image: "/images/project1d.jpg",
    },
    {
      id: "5",
      name: "Project Name",
      type: "Brading",
      image: "/images/project1c.jpg",
    },
    {
      id: "6",
      name: "Project Name",
      type: "Brading",
      image: "/images/project2.jpg",
    },
    {
      id: "7",
      name: "Project Name",
      type: "Brading",
      image: "/images/project2a.jpg",
    },
    {
      id: "8",
      name: "Project Name",
      type: "Brading",
      image: "/images/project2b.jpg",
    },
    {
      id: "9",
      name: "Project Name",
      type: "Brading",
      image: "/images/project2d.jpg",
    },
    {
      id: "10",
      name: "Project Name",
      type: "Brading",
      image: "/images/project2c.jpg",
    },
    {
      id: "11",
      name: "Project Name",
      type: "Brading",
      image: "/images/project3.jpg",
    },
    {
      id: "12",
      name: "Project Name",
      type: "Brading",
      image: "/images/project4.jpg",
    },
    {
      id: "13",
      name: "Project Name",
      type: "Brading",
      image: "/images/project5.jpg",
    },
    {
      id: "14",
      name: "Project Name",
      type: "Brading",
      image: "/images/project5a.jpg",
    },
    {
      id: "15",
      name: "Project Name",
      type: "Brading",
      image: "/images/project6.jpg",
    },
    {
      id: "16",
      name: "Project Name",
      type: "Brading",
      image: "/images/project7.jpg",
    },
    {
      id: "17",
      name: "Project Name",
      type: "Brading",
      image: "/images/project8.jpg",
    },
    {
      id: "18",
      name: "Project Name",
      type: "Brading",
      image: "/images/project8a.jpg",
    },
    {
      id: "19",
      name: "Project Name",
      type: "Brading",
      image: "/images/project9.jpg",
    }
  ];
  return (
    <div className="fh5co-narrow-content">
      <Title title={"ourProjects"} />
      <div className="row row-bottom-padded-md">
        {sections.map(
          (section: { id: string; name: string }, index: number) => (
            <div className="ProjectsSection" key={section.id + index}>
              <Title title={section.name} classN="fh5co-heading-colored" />
              <PhotoProvider speed={() => 800} loop={true} maskOpacity={0.5}>
                {projects 
                  .filter((project) => project.image.includes(`/${section.id}`))
                  ?.map((project, index) => (
                    <div
                      className="col-md-3 col-sm-6 col-padding text-center animate-box"
                      key={project.id}
                    >
                      <PhotoView key={index} src={project.image}>
                        <img
                          src={project.image}
                          style={{ objectFit: "cover", maxHeight: "200px" }}
                          className="img-responsive img-rounded projectImg pointer"
                          alt={project.name}
                        />
                      </PhotoView>
                    </div>
                  ))}
              </PhotoProvider>
            </div>
          )
        )}

        {/* <Project {...project} /> */}
      </div>
    </div>
  );
};

export default ProjectsCards;
