import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const BannersSlider = () => {
  const { t } = useTranslation("common");

  const banners = [
    {
      id: 1,
      bgUrl: "images/moz-image.jpg",
    },

    {
      id: 3,
      bgUrl: "images/moz-image2.jpg",
    },
    {
      id: 2,
      bgUrl: "images/moz-image4.jpg",
    },
    {
      id: 4,
      bgUrl: "images/moz-image3.jpg",
    },
  ];
  return (
    <Carousel
      axis={"vertical"}
      showArrows={true}
      stopOnHover={true}
      width={"100%"}
      verticalSwipe={"natural"}
      infiniteLoop={true}
      swipeable={true}
      autoPlay={true}
      transitionTime={1000}
    >
      {banners.map((banner) => (
        <div
          className="mob-slider"
          key={banner.id}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + banner.bgUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom center",
            minHeight: "600px",
            position: "relative",
          }}
        >
          <div className="overlay"></div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 text-center js-fullheight slider-text"></div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default BannersSlider;
