import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

const Title = ({ title, classN }: { title: string, classN?:string }) => {
  const { i18n ,t } = useTranslation("common");
  const fadeInStart = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(${i18n.language === "en" ? "-50px" : "50px"})`,
    },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 1000 }, // Adjust the duration as needed
  });
  return (
    <animated.h1
      style={fadeInStart}
      data-animate-effect="fadeInStart"
      className={`animate-box ${classN ? classN : 'fh5co-heading'}`}
    >
      {t(title)}
    </animated.h1>
  );
};

export default Title;
