import Service from "../shared/components/Service";
import { useTranslation } from "react-i18next";
import Title from "../shared/components/Title";
import LICENSES_ACCREDITATIONS from '../assets/images/LICENSES_ACCREDITATIONS.png';
import PROJECT_MANAGEMENT from '../assets/images/PROJECT_MANAGEMENT.png';
import ENGINEERING_SUPERVISION from '../assets/images/ENGINEERING_SUPERVISION.png';
import MAINTENANCE_WORK from '../assets/images/MAINTENANCE_WORK.png';
import DESIGNS from '../assets/images/DESIGNS.png';

const Services = () => {
  const { t } = useTranslation("common");
  const services = [
    {
      id: 2,
      title: t("services.service2"),
      paragraph: t("services.service2"),
      icon: LICENSES_ACCREDITATIONS,
    },
    {
      id: 3,
      title: t("services.service3"),
      paragraph: t("services.service3"),
      icon: PROJECT_MANAGEMENT,
    },
    {
      id: 4,
      title: t("services.service4"),
      paragraph: t("services.service4"),
      icon: ENGINEERING_SUPERVISION,
    },
    {
      id: 5,
      title: t("services.service5"),
      paragraph: t("services.service5"),
      icon: MAINTENANCE_WORK,
    },
    {
      id: 1,
      title: t("services.service1"),
      paragraph: t("services.service1"),
      icon: DESIGNS,
    },
  ];
  return (
    <div className="fh5co-narrow-content">
      <Title title={t("services.title")} />
      <div className="row">
        {services.map((service) => (
          <div className="col-md-6" key={service.id}>
            <Service {...service} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
