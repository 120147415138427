const ServiceCard = ({
  title,
  paragraph,
  image,
}: {
  title: string;
  paragraph: string;
  image: string;
}) => {
  return (
    <div className="blog-entry">
      <a href="/" className="blog-img">
        <img
          src={`${process.env.PUBLIC_URL + image}`}
          className="img-responsive"
          alt=""
        />
      </a>
      <div className="desc">
        <h3>
          <a href="/">{title}</a>
        </h3>
        {/* <span>
          <small>by Admin </small> / <small> Web Design </small> /
          <small>
            {" "}
            <i className="icon-comment"></i> 14
          </small>
        </span> */}
        <p>{paragraph}</p>
        {/* <a href="/" className="lead">
          Read More <i className="icon-arrow-right3"></i>
        </a> */}
      </div>
    </div>
  );
};

export default ServiceCard;
