import ProjectsCards from "../components/ProjectsCards";
import GetInTouch from "../shared/components/GetInTouch";

const Projects = () => {
  return (
    <div>
      <ProjectsCards />
      <GetInTouch />
    </div>
  );
};

export default Projects;
