import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

const Service = ({
  id,
  title,
  paragraph,
  icon,
}: {
  id: number;
  title: string;
  paragraph: string;
  icon: any;
}) => {
  const { i18n } = useTranslation("common");
  const fadeInStart = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(${i18n.language === "en" ? "-50px" : "50px"})`,
    },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 1000 }, // Adjust the duration as needed
  });
  return (
    <animated.div
      className="fh5co-feature animate-box"
      data-animate-effect="fadeInStart"
      style={fadeInStart}
    >
      <div className="fh5co-icon">
        {/* <i className={icon}></i> */}
        <img src={icon} alt="" />
      </div>
      <div className="fh5co-text">
        <h3>{title}</h3>
        {/* <p>{paragraph}</p> */}
      </div>
    </animated.div>
  );
};

export default Service;
