import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

const BannerWTitle = ({
  name,
  paragraph1,
  paragraph2,
  image,
}: {
  name: string;
  paragraph1: string;
  paragraph2: string;
  image: string;
}) => {
  const { i18n } = useTranslation("common");
  const fadeInStart = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(${i18n.language === "en" ? "-50px" : "50px"})`,
    },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 1000 }, // Adjust the duration as needed
  });
  return (
    <div className="fh5co-narrow-content">
      <div className="row row-bottom-padded-md">
        <animated.div
          className="col-md-6 animate-box row-bottom-padded-md"
          data-animate-effect="fadeInStart"
          style={fadeInStart}
        >
          <img
            className="img-responsive img-rounded"
            src={`${process.env.PUBLIC_URL + image}`}
            alt=""
          />
        </animated.div>
        <animated.div
          className="col-md-6 animate-box"
          data-animate-effect="fadeInStart"
          style={fadeInStart}
        >
          <h2 className="fh5co-heading">{name}</h2>
          <h5>{paragraph1}</h5>
          <h5>{paragraph2}</h5>
        </animated.div>
      </div>
    </div>
  );
};

export default BannerWTitle;
