import "./App.scss";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import SideMenu from "./shared/components/SideMenu";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
function App() {
  const { t, i18n } = useTranslation("common");
  return (
    <div className="App" dir={i18n.language === "en" ? "ltr" : "rtl"}>
      <div id="fh5co-page">
        <SideMenu />
      </div>
      <div id="fh5co-main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
